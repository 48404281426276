import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html,
html.wf-loading {
  body {
    opacity: 0;
    transition: 250ms opacity ease;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}

#gatsby-focus-wrapper{
  outline: 0 !important;
}

body {
  font-family: "Lars-RegularWeb", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.03em;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #0059BE;
  margin: 0;

  @media (max-width: 1280px) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: 960px) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 18px;
  }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "Lars-RegularWeb", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;

  margin: 0;
}


/* Title 1 */
h1{
  font-size: 30px;
  line-height: 32px;

  @media (max-width: 1280px) {
    font-size: 28px;
    line-height: 30px;
  }

  @media (max-width: 960px) {
    font-size: 20px;
    line-height: 20px;
  }

  @media (max-width: 500px) {
    font-size: 22px;
    line-height: 22px;
  }

  &.h2{
    font-size: 20px;
    line-height: 27px;

    @media (max-width: 1280px) {
      font-size: 18px;
      /* line-height: 32px; */
    }

    @media (max-width: 960px) {
      font-size: 14px;
      line-height: 20px;
    }

    @media (max-width: 500px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

/* Title 2 */
h2 {
  font-size: 20px;
  line-height: 34px;

  @media (max-width: 1280px) {
    font-size: 18px;
    line-height: 32px;
  }

  @media (max-width: 960px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 500px) {
      font-size: 16px;
      line-height: 22px;
  }
}

/* Heading 1 */
h3 {
  font-size: 30px;
  line-height: 32px;
}

/* Heading 2 */
h4 {
  font-size: 18px;
  line-height: 24px;
}

/* Heading 3 */
h5 {
  font-size: 12px;
  line-height: 14px;
}

.extra-small-body-text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03em;
}

.small-body-text {
  font-size: 16px;
  letter-spacing: 0.03em;

  @media (max-width: 1280px) {
    font-size: 14px;
  }

  @media (max-width: 960px) {
    font-size: 10px;
  }

  @media (max-width: 500px) {
    font-size: 12px;
  }
}

.medium-body-text {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.03em;

  @media (max-width: 1280px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 960px) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.stratos {
  font-family: "stratos-semibold", "Helvetica Neue", "Lucida Grande", sans-serif;
}

.uppercase {
  text-transform: uppercase;
}

em {
  font-family: "Lars-RegularItalicWeb", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style:normal;
}

.light-blue{
  color: rgba(0, 89, 190, 0.5);
}

/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    border:0;
    margin:0;
    padding: 0;

    font-weight:normal;
    font-style: normal;

    appearance: none;
    cursor:pointer;
    color: inherit;

    &:focus,
    &:hover{
      outline: none;
    }
  }


input[type="hidden"],
textarea[type="hidden"] {
  appearance: none;
  display: none;
}


/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, ol, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}


/*--------------------------------------------------------------
  ## Gatsby Image Preview Fix
  --------------------------------------------------------------*/

.gatsby-image-wrapper-constrained{
  width: 100%;
}

/*--------------------------------------------------------------
  ## Input Placeholders
  --------------------------------------------------------------*/

// ::-webkit-input-placeholder{
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// ::-moz-placeholder{
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// :-ms-input-placeholder{
//       color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// :-moz-placeholder {
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

//   }




/*--------------------------------------------------------------
  ## React Modal
--------------------------------------------------------------*/
.ReactModalPortal{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 100;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModalPortal-mailchimp,
.ReactModalPortal-gallery,
.ReactModalPortal-mobile-menu {
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 10000;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModalPortal-mobile-menu .ReactModal__Overlay,
.ReactModalPortal-mailchimp .ReactModal__Overlay {
  background-color: #f5eadf !important;
}

.ReactModalPortal-gallery .ReactModal__Overlay {
  background-color: #fff !important;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content{
  width: 100%;
  height: 100%;

  inset: 0 !important;
  background: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}
`;

export default GlobalStyle;
